import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6FFFE9',
    },
    secondary: {
      main: '#3A506B',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#0B132B',
    },
    surface: {
      default: '#e8e8e8',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    divider: '#fff',
  },
  spacing: 3,
});

export default theme;
