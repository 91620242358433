import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Bookmarks from '@material-ui/icons/Bookmarks';
import ExtensionIcon from '@material-ui/icons/Extension';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Favorite from '@material-ui/icons/Favorite';
import MailIcon from '@material-ui/icons/Mail';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '30px',
    },
    bar: {
      backgroundColor: 'transparent',
      height: '100px',
      boxShadow: 'none',
      position: 'absolute',
    },
    logo: {
      height: '50px',
      position: 'absolute',
      top: '15%',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    menuButton: {
      marginLeft: theme.spacing(2),
      marginTop: '-15px',
    },
    title: {
      flexGrow: 1,
    },
    button: {
      padding: '30px',
      fontSize: '18px',
    },
    unstyledLink: {
      textDecoration: 'none',
    },
    menuItem: {
      color: 'black',
      textDecoration: 'none',
      width: '170px',
    },
    menu: {
      width: '190px',
      '&:active': {
        border: 'none',
      },
    },
  }),
);

export default function MenuBar() {
  const classes = useStyles();
  const smaller = useMediaQuery('(max-width:835px)');
  const bigger = useMediaQuery('(min-width:836px)');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Toolbar className={classes.bar}>
          {smaller && (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="menu"
                onClick={handleClick}
                aria-controls="mobile-menu"
                aria-haspopup="true"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="mobile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                PopoverClasses={{ paper: classes.menu }}
                variant="menu"
                onClose={handleClose}
              >
                <Link to="/" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home"> </ListItemText>
                  </MenuItem>
                </Link>
                {/* <Link to="/services" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <ExtensionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Services" />
                  </MenuItem>
                </Link> */}
                {/* <Link to="/pricing" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Flavors" />
                  </MenuItem>
                </Link> */}
                <Link to="/mvp-development-company" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Favorite />
                    </ListItemIcon>
                    <ListItemText primary="MVPs" />
                  </MenuItem>
                </Link>
                <Link to="/blog" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <InsertCommentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Blog" />
                  </MenuItem>
                </Link>
                {/* <Link to="/testimonials" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Bookmarks />
                    </ListItemIcon>
                    <ListItemText primary="Testimonials" />
                  </MenuItem>
                </Link> */}
                <Link to="/contact" className={classes.menuItem}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact" />
                  </MenuItem>
                </Link>
              </Menu>
            </>
          )}
          {bigger && (
            <Box className={classes.menuButtons}>
              <Link to="/" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Home
                </Button>
              </Link>
              {/* <Link to="/services" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Services
                </Button>
              </Link> */}
              {/* <Link to="/pricing" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Flavors
                </Button>
              </Link> */}
              <Link to="/mvp-development-company" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  MVPs
                </Button>
              </Link>
              <Link to="/blog" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Blog
                </Button>
              </Link>
              {/* <Link to="/testimonials" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Testimonials
                </Button>
              </Link> */}
              <Link to="/contact" className={classes.unstyledLink}>
                <Button color="primary" className={classes.button}>
                  Contact
                </Button>
              </Link>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
